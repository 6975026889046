<template>
    <q-page>
        <div class="row">
            <q-space />
            <div class="col-lg-7 col-md-9 col-sm-10 col-xs-12">
                <div class="row q-my-lg">
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-4 flex flex-center">
                        <q-btn
                            flat
                            label="Atrás"
                            class="w700"
                            icon="fas fa-long-arrow-alt-left"
                            rounded
                            size="sm"
                            to="/"
                            :color="entity.accentColor"
                        />
                    </div>
                    <q-space />
                    <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 flex flex-center">
                        <q-btn
                            no-caps
                            label="Descargar PDF"
                            icon="far fa-file-pdf"
                            class="w700"
                            push
                            rounded
                            :color="entity.primaryColor"
                            @click="generateReport()"
                        />
                    </div>
                </div>
                <div v-if="!loading">
                    <div class="q-pa-md" v-if="hideForMobile">
                        <DocumentComponent
                            class="shadow-3"
                            :data="documentData"
                            v-if="entity.id == 'SLUiKLonGGCn3xgBLkWa'"
                        />
                        <GenericDocumentComponent class="shadow-3" :data="documentData" v-else />
                    </div>
                    <div class="q-px-md" v-else>
                        <MobileDocumentPreview :data="documentData" />
                    </div>
                </div>

                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="2000"
                    :filename="`${entity.name}-${documentData.type}-${documentData.number}`"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    :pdf-format="returnPaperSize()"
                    pdf-orientation="portrait"
                    pdf-content-width="100%"
                    ref="html2Pdf"
                >
                    <section slot="pdf-content">
                        <DocumentComponent
                            :data="documentData"
                            v-if="entity.id == 'SLUiKLonGGCn3xgBLkWa'"
                        />
                        <GenericDocumentComponent :data="documentData" v-else />
                    </section>
                </vue-html2pdf>
            </div>
            <div class="col-lg-4">
                <div class="row q-my-lg q-px-md">
                    <q-space />
                    <q-btn
                        push
                        no-caps
                        label="Guardar"
                        class="w700"
                        icon-right="far fa-save"
                        size="sm"
                        rounded
                        :color="entity.primaryColor"
                        @click="saveDocument(documentData)"
                    />
                </div>
                <div class="q-pa-md">
                    <q-card>
                        <q-card-section>
                            <div :class="`text-h6 w700 text-${entity.accentColor}`">Nuevo</div>
                        </q-card-section>
                        <q-card-section>
                            <q-select
                                label="Cliente"
                                :color="entity.primaryColor"
                                :options="allClients"
                                filled
                                class="q-mb-md"
                                v-model="documentData.clientData"
                                map-options
                                emit-value
                            />
                            <q-separator class="q-my-md" />
                            <div class="q-mb-md" v-for="(item, i) in documentData.items" :key="i">
                                <div class="text-caption w700 q-mb-sm">Item {{ i + 1 }}</div>
                                <q-input
                                    label="Nombre del item"
                                    filled
                                    :color="entity.primaryColor"
                                    class="q-mb-md"
                                    v-model="documentData.items[i].name"
                                >
                                    <template v-slot:after>
                                        <q-btn
                                            round
                                            dense
                                            flat
                                            icon="delete"
                                            @click="removeItem(i)"
                                            :disable="documentData.items.length > 1 ? false : true"
                                        />
                                    </template>
                                </q-input>
                                <q-input
                                    label="Descripción del item (opcional)"
                                    filled
                                    :color="entity.primaryColor"
                                    class="q-mb-md"
                                    v-model="documentData.items[i].description"
                                />
                                <div class="row">
                                    <div class="col on-left">
                                        <q-input
                                            label="Precio"
                                            filled
                                            :color="entity.primaryColor"
                                            v-model.number="documentData.items[i].price"
                                        />
                                    </div>
                                    <div :class="'col' + entity.collectsTaxes ? '' : 'on-right'">
                                        <q-input
                                            label="Cantidad"
                                            filled
                                            :color="entity.primaryColor"
                                            v-model.number="documentData.items[i].amount"
                                        />
                                    </div>
                                    <div class="col on-right" v-if="entity.collectsTaxes">
                                        <q-input
                                            label="Porcentaje de impuestos"
                                            filled
                                            type="number"
                                            mask="number"
                                            :color="entity.primaryColor"
                                            v-model.number="documentData.items[i].tax"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <q-space />
                                <q-btn
                                    flat
                                    label="Add item"
                                    icon="add"
                                    no-caps
                                    rounded
                                    :color="entity.primaryColor"
                                    @click="addNewItem()"
                                />
                            </div>
                            <q-separator class="q-my-md" />
                            <q-input
                                type="textarea"
                                rows="4"
                                label="Notes"
                                filled
                                :color="entity.primaryColor"
                                v-model="documentData.notes"
                            />
                        </q-card-section>
                    </q-card>
                </div>
            </div>
            <q-space />
        </div>
    </q-page>
</template>

<script>
import {Platform} from 'quasar'
import VueHtml2pdf from 'vue-html2pdf'
import firebase from 'firebase/app'
import 'firebase/firestore'

import DocumentComponent from '@/components/DocumentComponent'
import GenericDocumentComponent from '@/components/GenericDocumentComponent'
import MobileDocumentPreview from '@/components/MobileDocumentPreview'

export default {
    components: {
        VueHtml2pdf,
        DocumentComponent,
        GenericDocumentComponent,
        MobileDocumentPreview,
    },
    data() {
        return {
            documentData: {
                notes: '',
                date: Date.now(),
                items: [],
            },
            loading: false,
        }
    },
    computed: {
        hideForMobile() {
            if (Platform.is.iphone || Platform.is.android) return false
            else return true
        },
        allClients() {
            return this.$store.getters.allClients.map(client => {
                return {
                    label: client.name,
                    value: client,
                }
            })
        },
        allQuotes() {
            return this.$store.getters.allQuotes
        },
        entity() {
            return this.$store.getters.entity
        },
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        returnPaperSize() {
            try {
                if (this.entity.id == 'NPB33XGKOAfjd7MpHJFo') return 'legal'
                else {
                    if (this.documentData.items.length <= 5) return 'letter'
                    if (this.documentData.items.length > 5) return 'legal'
                }
            } catch (e) {}
        },
        addNewItem() {
            this.documentData.items.push({
                name: '',
                description: '',
                price: 0,
                amount: 1,
                tax: 0,
            })
        },
        removeItem(index) {
            this.documentData.items.splice(index, 1)
        },
        saveDocument(data) {
            try {
                if (data.items.length > 0) {
                    data.entities = this.entity.id
                    firebase
                        .firestore()
                        .collection(`${data.type}s`)
                        .add(data)
                        .then(() => {
                            let obj = {}
                            obj[`last${data.type.charAt(0).toUpperCase() + data.type.slice(1)}`] =
                                data.number
                            firebase
                                .firestore()
                                .collection('entities')
                                .doc(this.entity.id)
                                .update(obj)
                        })
                        .then(async () => {
                            alert('Se ha guardado con exito')
                            data.type === 'invoice'
                                ? await this.$store.dispatch('getAllInvoices', this.entity.id)
                                : await this.$store.dispatch('getAllQuotes', this.entity.id)
                            this.$router.push(`/${data.type}s`)
                        })
                } else {
                    alert('El documento esta todo vacio')
                }
            } catch (error) {
                alert('Lo sentimos hubo un error! Por favor vuelve a intentarlo')
                console.log(error)
            }
        },
    },
    async mounted() {
        this.loading = true
        if (this.$route.params.id) {
            //Entra aqui cuando se crea una factura de un quote
            firebase
                .firestore()
                .collection('quotes')
                .doc(this.$route.params.id)
                .get()
                .then(doc => {
                    this.documentData = doc.data()
                    this.documentData.type = 'invoice'
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false
                })

            return
        }
        this.documentData.type = this.$route.params.type
        firebase
            .firestore()
            .collection('entities')
            .doc(this.entity.id)
            .get()
            .then(snapshot => {
                let data = snapshot.data()
                let number = ''
                if (this.documentData.type === 'invoice') {
                    number = `${parseInt(data.lastInvoice) + 1}`
                    while (number.length < 5) {
                        number = '0' + number
                    }
                } else {
                    number = `${parseInt(data.lastQuote) + 1}`
                    while (number.length < 5) {
                        number = '0' + number
                    }
                }
                this.documentData.number = number
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.loading = false
            })
    },
}
</script>
