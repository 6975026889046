<template>
    <q-page>
        <div class="row q-pt-xl">
            <q-space />
            <div class="col-lg-7 col-md-9 col-sm-10 col-xs-12">
                <div class="row q-mb-md q-px-md">
                    <div :class="`text-h4 w700 text-${entity.accentColor}`">Facturas</div>
                </div>
                <div class="row q-mb-md q-px-md">
                    <q-btn
                        flat
                        label="Atrás"
                        class="w700"
                        icon="fas fa-long-arrow-alt-left"
                        rounded
                        size="sm"
                        to="/"
                        :color="entity.accentColor"
                    />
                    <q-space />
                    <q-btn
                        flat
                        label="Nuevo"
                        class="w700"
                        icon-right="far fa-file"
                        rounded
                        size="sm"
                        to="/document/create/invoice"
                        :color="entity.primaryColor"
                    />
                    <q-btn
                        flat
                        label="De Cot."
                        class="w700"
                        icon-right="fas fa-file-invoice-dollar"
                        rounded
                        size="sm"
                        :color="entity.secondaryColor"
                        @click="fromQuoteDialog = true"
                    />
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 q-px-md">
                        <div class="row">
                            <q-table
                                class="full-width"
                                :data="allInvoices"
                                :columns="columns"
                                row-key="name"
                                binary-state-sort
                                :pagination="initialPagination"
                            >
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td key="number" :props="props">{{
                                            props.row.number
                                        }}</q-td>
                                        <q-td key="client" :props="props">{{
                                            props.row.clientData.name
                                        }}</q-td>
                                        <q-td key="monto">
                                            {{ formatCurrency(calculateSubTotal(props.row)) }}</q-td
                                        >
                                        <q-td key="ibms" v-if="entity.collectsTaxes">
                                            {{ formatCurrency(calculateITBMS(props.row)) }}</q-td
                                        >
                                        <q-td key="total" v-if="entity.collectsTaxes">
                                            {{ formatCurrency(calculateTotal(props.row)) }}</q-td
                                        >
                                        <q-td>
                                            <q-btn-group rounded flat>
                                                <!-- <q-btn icon="delete" size="sm" flat /> -->
                                                <q-btn
                                                    icon="fas fa-arrow-right"
                                                    size="sm"
                                                    flat
                                                    :to="`/document/view/invoice/${props.row.id}`"
                                                />
                                            </q-btn-group>
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </div>
            </div>
            <q-space />
        </div>

        <q-dialog v-model="fromQuoteDialog">
            <q-card class="bb-font" style="width: 700px; max-width: 99vw">
                <q-card-section>
                    <div class="text-h5 text-dark w700">Crear factura de cotización</div>
                </q-card-section>
                <q-card-section>
                    <q-input label="Buscar cotización" filled v-model="searchQuote" autofocus />
                </q-card-section>
                <q-card-section v-if="filterQuotes.length > 0 && searchQuote">
                    <div
                        class="row w700 q-pt-md"
                        v-for="quote in filterQuotes"
                        :key="quote.id"
                        style="border-bottom: solid 1px #e6e6e6"
                    >
                        <div class="col-lg-5 col-xs-3">{{ quote.number }}</div>
                        <div class="col-lg-5 col-xs-7">{{ quote.clientData.name }}</div>
                        <div class="col-lg-2 col-xs-2">
                            <q-btn
                                icon="forward"
                                color="primary"
                                flat
                                round
                                size="sm"
                                @click="createInvoiceFromQuote(quote)"
                            />
                        </div>
                    </div>
                </q-card-section>
                <q-card-section v-if="filterQuotes.length == 0 && searchQuote">
                    <div class="text-h6 text-grey-4 w700">No results found.</div>
                </q-card-section>
                <q-card-actions>
                    <q-space />
                    <q-btn
                        label="Cancelar"
                        flat
                        rounded
                        color="dark"
                        class="w700 bb-font"
                        v-close-popup
                    />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </q-page>
</template>

<script>
export default {
    data() {
        return {
            fromQuoteDialog: false,
            searchQuote: '',
            initialPagination: {
                sortBy: 'number',
                descending: true,
                rowsPerPage: 15,
            },
            columns: [
                {
                    name: 'number',
                    label: 'No.',
                    align: 'left',
                    field: 'number',
                    sortable: true,
                },
                {
                    name: 'client',
                    label: 'Cliente',
                    align: 'client',
                    field: 'name',
                    sortable: true,
                    align: 'left',
                },
            ],
        }
    },
    computed: {
        allQuotes() {
            return this.$store.getters.allQuotes
        },
        allInvoices() {
            return this.$store.getters.allInvoices
        },
        entity() {
            return this.$store.getters.entity
        },
        filterQuotes() {
            let filteredQuotes = []
            this.allQuotes.forEach(quote => {
                if (quote.number.includes(this.searchQuote)) filteredQuotes.push(quote)
            })
            return filteredQuotes
        },
    },
    methods: {
        createInvoiceFromQuote(quote) {
            this.$router.push(`/document/create/invoice/${quote.id}`)
        },
        calculateITBMS(data) {
            let itbms = 0
            if (data.items) {
                if (this.entity.collectsTaxes) {
                    data.items.forEach(item => {
                        item.tax = item.tax
                        itbms += parseFloat(item.price) * (item.tax / 100) * item.amount
                    })
                    return itbms.toFixed(2)
                }
                return 0
            }
        },
        calculateSubTotal(data) {
            let subtotal = 0
            if (data.items) {
                data.items.forEach(item => {
                    subtotal += parseFloat(item.price) * parseFloat(item.amount)
                })
            }
            return subtotal.toFixed(2)
        },
        calculateTotal(data) {
            return this.entity.collectsTaxes
                ? parseFloat(this.calculateSubTotal(data)) + parseFloat(this.calculateITBMS(data))
                : parseFloat(this.calculateSubTotal(data))
        },
        formatCurrency(money, amount = null) {
            if (amount) money = parseFloat(money) * parseFloat(amount)
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(money)
        },
    },
    mounted() {
        this.$store.dispatch('getAllInvoices', this.entity.id)
        if (this.entity.collectsTaxes) {
            this.columns.push(
                {name: 'monto', label: 'Monto', align: 'left'},
                {name: 'itbms', label: 'Itbms', align: 'left'},
                {name: 'total', label: 'Total', align: 'left'},
                {name: 'actions', label: 'Acciones', align: 'left'}
            )
        } else {
            this.columns.push(
                {name: 'monto', label: 'Total', align: 'left'},
                {name: 'actions', label: 'Acciones', align: 'left'}
            )
        }
    },
}
</script>
