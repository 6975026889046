import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
    state: {
        allQuotes: [],
        existingQuote: {},
    },
    mutations: {
        SET_ALLQUOTES(state, payload) {
            state.allQuotes = payload
        },
        SET_EXISTINGQUOTE(state, payload) {
            state.existingQuote = payload
        },
        RESET_STATES(state) {
            state.allQuotes = []
            state.existingQuote = {}
        },
        DELETE_QUOTE(state, payload) {
            state.allQuotes = state.allQuotes.filter(quote => quote.id != payload)
        },
    },
    actions: {
        getAllQuotes({commit}, entityId) {
            firebase
                .firestore()
                .collection('quotes')
                .where('entities', '==', entityId)
                .get()
                .then(querySnapshot => {
                    let data = []
                    querySnapshot.forEach(doc => {
                        data.push({id: doc.id, ...doc.data()})
                    })
                    commit('SET_ALLQUOTES', data)
                })
        },
    },
    getters: {
        allQuotes: state => state.allQuotes,
    },
}
