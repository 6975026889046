import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import AuthLayout from '@/layouts/AuthLayout'
import MainLayout from '@/layouts/MainLayout'

import LoginView from '@/views/LoginView'
import Home from '@/views/Home.vue'
import QuotesView from '@/views/QuotesView'
import InvoicesView from '@/views/InvoicesView'
import ClientsView from '@/views/ClientsView'
import CreateDocument from '@/views/CreateDocument'
import ViewDocument from '@/views/ViewDocument'
import EditDocument from '@/views/EditDocument'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
    } else next('/login')
}

const routes = [
    {
        path: '/login',
        component: AuthLayout,
        children: [
            {
                path: '/login',
                name: 'Login',
                component: LoginView,
            },
        ],
    },
    {
        path: '/',
        component: MainLayout,
        beforeEnter: ifAuthenticated,
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
            },
            {
                path: '/quotes',
                name: 'QuotesView',
                component: QuotesView,
            },
            {
                path: '/document/create/:type',
                name: 'CreateDocument',
                component: CreateDocument,
            },
            {
                path: '/document/create/invoice/:id?',
                name: 'CreateDocument',
                component: CreateDocument,
            },
            {
                path: '/document/view/:type/:id',
                name: 'ViewDocument',
                component: ViewDocument,
            },
            {
                path: '/document/edit/:type/:id',
                name: 'EditDocument',
                component: EditDocument,
            },
            {
                path: '/invoices',
                name: 'InvoicesView',
                component: InvoicesView,
            },
            {
                path: '/clients',
                name: 'ClientsView',
                component: ClientsView,
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
