<template>
    <q-page>
        <div class="row">
            <div class="col-lg-7 col-md-6 col-xs-12 sm-hide xs-hide" style="height: 100vh">
                <div class="flex flex-center" style="height: 100%">
                    <q-img :src="require('@/assets/logo_2.webp')" style="width: 100px" />
                    <div class="text-dark q-ml-lg">
                        <span class="text-h5 w700">Invoice Manager</span>
                        <br />
                        <span class="text-subtitle2 w700 q-pl-xl">By Blue Balloon Inc.</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-6 col-xs-12 q-py-lg xl-hide lg-hide md-hide">
                <div class="flex flex-center" style="height: 100%">
                    <q-img :src="require('@/assets/logo_2.webp')" style="width: 50px" />
                    <div class="text-dark q-ml-lg">
                        <span class="text-h5 w700">Invoice Manager</span>
                        <br />
                        <span class="text-subtitle2 w700 q-pl-xl">By Blue Balloon Inc.</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 col-xs-12 gradient-bg shadow-5" style="height: 100vh">
                <div class="flex flex-center" style="height: 100%">
                    <q-card style="width: 450px; max-width: 80vw" class="login-card">
                        <q-card-section class="q-pa-lg">
                            <div class="text-h5 w700 text-dark">Iniciar sesion.</div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section class="q-pa-lg">
                            <q-input
                                label="Email"
                                filled
                                type="email"
                                class="q-mb-md"
                                v-model="email"
                                input-email-login
                            />
                            <q-input
                                label="Password"
                                filled
                                :type="isPwd ? 'password' : 'text'"
                                v-model="password"
                                input-password-login
                                @keyup.enter="login()"
                            >
                                <template v-slot:append>
                                    <q-icon
                                        :name="isPwd ? 'visibility_off' : 'visibility'"
                                        class="cursor-pointer"
                                        @click="isPwd = !isPwd"
                                    />
                                </template>
                            </q-input>
                        </q-card-section>
                        <q-separator />
                        <q-card-actions class="q-pa-lg">
                            <q-btn
                                push
                                unelevated
                                rounded
                                btn-login-login
                                class="w700 full-width"
                                color="primary"
                                icon-right="login"
                                @click="login()"
                                :disable="displayLoading"
                            >
                                <span v-if="!displayLoading">Iniciar sesión</span>
                                <q-spinner-facebook v-if="displayLoading" color="white" size="1em"
                            /></q-btn>
                            <q-card-section v-if="dismissCountDown > 0" class="q-px-none">
                                <q-banner inline-actions rounded class="bg-red text-white">
                                    {{ errorMessage }}
                                    <template v-slot:action>
                                        <q-btn flat @click="dismissCountDown = 0" round size="sm">
                                            <i class="fas fa-times"></i>
                                        </q-btn>
                                    </template>
                                </q-banner>
                            </q-card-section>
                        </q-card-actions>
                    </q-card>
                </div>
            </div>
        </div>
    </q-page>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
export default {
    data() {
        return {
            isPwd: true,
            email: 'diego@blueballoon.io',
            password: 'BlueBalloon123!',
            dismissSecs: 15,
            dismissCountDown: 0,
            errorMessage: '',
            currentUser: '',
            displayLoading: false,
        }
    },
    methods: {
        async login() {
            this.displayLoading = true
            firebase
                .auth()
                .signInWithEmailAndPassword(this.email.trim().toLowerCase(), this.password)
                .then(async () => {
                    this.currentUser = await firebase.auth().currentUser
                    await this.$store.dispatch('setCurrentUser', this.currentUser)
                    firebase
                        .firestore()
                        .collection('entities')
                        .where('members', 'array-contains', this.currentUser.uid)
                        .limit(1)
                        .get()
                        .then(async querySnapshot => {
                            if (querySnapshot.empty) {
                                throw new Error(
                                    'Hubo un problema con la búsqueda, por favor intentalo mas tarde.'
                                )
                            }
                            let data = {
                                ...querySnapshot.docs[0].data(),
                                id: querySnapshot.docs[0].id,
                            }
                            await this.$store.dispatch('setUserEntity', data)
                            await this.$store.dispatch('getAllClients', data.id)
                            await this.$store.dispatch('getAllQuotes', data.id)
                            await this.$store.dispatch('getAllInvoices', data.id)
                            this.$router.push('/')
                        })
                        .catch(error => {
                            console.error(error)
                            throw new Error(
                                'Hubo un problema con la solicitud, por favor intentalo mas tarde.'
                            )
                        })
                })
                .catch(error => {
                    this.dismissCountDown = this.dismissSecs
                    this.displayLoading = false
                    switch (error.code) {
                        case 'auth/user-disabled':
                            this.errorMessage =
                                'La cuenta esta deshabilitada por favor comunicarse con un administrador.'
                            break
                        case 'auth/user-not-found':
                            this.errorMessage =
                                'No se ha encontrado ese correo en nuestra base de datos por favor crea una cuenta.'
                            break
                        case 'auth/wrong-password':
                            this.errorMessage =
                                'El usuario o la contraseña está equivocado por favor revisar.'
                            break
                        case 'auth/invalid-email':
                            this.errorMessage =
                                'El usuario o la contraseña está equivocado por favor revisar.'
                            break
                        default:
                            this.errorMessage = error.message
                            break
                    }
                })
        },
    },
}
</script>

<style>
.gradient-bg {
    background: rgb(67, 192, 246) !important;
    background: linear-gradient(
        45deg,
        rgba(67, 192, 246, 1) 0%,
        rgba(92, 69, 173, 1) 100%
    ) !important;
}
.login-card {
    border-radius: 20px !important;
}
</style>
