import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './quasar'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.config.productionTip = false

const firebaseConfig = {
    apiKey: 'AIzaSyCmhqOfz2yaOmz6lEbp0bE1cuyFu2xlgSo',
    authDomain: 'invoice-manager-6e5f5.firebaseapp.com',
    projectId: 'invoice-manager-6e5f5',
    storageBucket: 'invoice-manager-6e5f5.appspot.com',
    messagingSenderId: '1073198062429',
    appId: '1:1073198062429:web:45c3cb7a5d89062b9226b4',
    measurementId: 'G-3WGPTS0RDW',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// comment lines bellow if you want to point to database in production
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'testing') {
    firebase.auth().useEmulator('http://localhost:9099/')
    firebase.firestore().useEmulator('localhost', 8081)
}

firebase.auth().onAuthStateChanged(async user => {
    if (user) {
        try {
            firebase
                .firestore()
                .collection('entities')
                .where('members', 'array-contains', user.uid)
                .limit(1)
                .get()
                .then(async querySnapshot => {
                    if (querySnapshot.empty) {
                        throw new Error(
                            'Hubo un problema con la búsqueda, por favor intentalo mas tarde.'
                        )
                    }
                    let data = {
                        ...querySnapshot.docs[0].data(),
                        id: querySnapshot.docs[0].id,
                    }
                    await store.dispatch('setCurrentUser', user)
                    await store.dispatch('setUserEntity', data)
                    await store.dispatch('getAllClients', data.id)
                    await store.dispatch('getAllQuotes', data.id)
                    await store.dispatch('getAllQuotes', data.id)
                })
                .then(() => {
                    new Vue({
                        router,
                        store,
                        render: h => h(App),
                    }).$mount('#app')
                })
                .catch(error => {
                    console.error(error)
                    throw new Error(
                        'Hubo un problema con la solicitud, por favor intentalo mas tarde.'
                    )
                })
        } catch (error) {}
    } else {
        new Vue({
            router,
            store,
            render: h => h(App),
        }).$mount('#app')
    }
})
