<template>
    <q-page>
        <div class="row">
            <q-space />
            <div class="col-lg-7 col-md-9 col-sm-10 col-xs-12">
                <div class="row q-my-lg">
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-4 flex flex-center">
                        <q-btn
                            flat
                            label="Atrás"
                            class="w700"
                            icon="fas fa-long-arrow-alt-left"
                            rounded
                            size="sm"
                            :to="`/${this.$route.params.type}s`"
                            :color="entity.accentColor"
                        />
                    </div>
                    <q-space />
                    <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 flex flex-center">
                        <q-btn
                            no-caps
                            label="Descargar PDF"
                            icon="far fa-file-pdf"
                            class="w700"
                            push
                            rounded
                            :color="entity.primaryColor"
                            @click="generateReport()"
                        />
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 flex flex-center">
                        <q-btn
                            v-if="documentData.type === 'quote'"
                            no-caps
                            label="Editar"
                            icon="fas fa-edit"
                            class="w700"
                            push
                            rounded
                            :color="entity.primaryColor"
                            :to="`/document/edit/quote/${documentId}`"
                        />
                    </div>
                </div>
                <div v-if="!loading">
                    <div class="q-pa-md" v-if="hideForMobile">
                        <DocumentComponent
                            class="shadow-3"
                            :data="documentData"
                            v-if="entity.id == 'SLUiKLonGGCn3xgBLkWa'"
                        />
                        <GenericDocumentComponent class="shadow-3" :data="documentData" v-else />
                    </div>
                    <div class="q-px-md" v-else>
                        <MobileDocumentPreview :data="documentData" v-if="documentData.number" />
                    </div>
                </div>

                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="2000"
                    :filename="`${entity.name}-${documentData.type}-${documentData.number}`"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    :pdf-format="returnPaperSize()"
                    pdf-orientation="portrait"
                    pdf-content-width="100%"
                    ref="html2Pdf"
                >
                    <section slot="pdf-content">
                        <DocumentComponent
                            :data="documentData"
                            v-if="entity.id == 'SLUiKLonGGCn3xgBLkWa'"
                        />
                        <GenericDocumentComponent :data="documentData" v-else />
                    </section>
                </vue-html2pdf>
            </div>

            <q-space />
        </div>
    </q-page>
</template>

<script>
import {Platform} from 'quasar'
import VueHtml2pdf from 'vue-html2pdf'
import firebase from 'firebase/app'
import 'firebase/firestore'

import DocumentComponent from '@/components/DocumentComponent'
import GenericDocumentComponent from '@/components/GenericDocumentComponent'
import MobileDocumentPreview from '@/components/MobileDocumentPreview'

export default {
    components: {
        VueHtml2pdf,
        DocumentComponent,
        GenericDocumentComponent,
        MobileDocumentPreview,
    },
    data() {
        return {
            documentData: {},
            loading: false,
            documentId: null,
        }
    },
    computed: {
        hideForMobile() {
            if (Platform.is.iphone || Platform.is.android) return false
            else return true
        },
        entity() {
            return this.$store.getters.entity
        },
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        returnPaperSize() {
            try {
                if (this.entity.id == 'NPB33XGKOAfjd7MpHJFo') return 'legal'
                else {
                    if (this.documentData.items.length <= 5) return 'letter'
                    if (this.documentData.items.length > 5) return 'legal'
                }
            } catch (e) {}
        },
    },
    async mounted() {
        this.loading = true
        this.documentData.type = this.$route.params.type
        this.documentId = this.$route.params.id
        firebase
            .firestore()
            .collection(`${this.documentData.type}s`)
            .doc(this.documentId)
            .get()
            .then(doc => {
                this.documentData = doc.data()
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.loading = false
            })
    },
}
</script>
