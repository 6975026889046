export default {
    state: {
        entity: '',
        logo: '',
        entityName: '',
    },
    mutations: {
        SET_ENTITY: (state, payload) => {
            state.entity = payload
        },
        SET_LOGO: (state, payload) => {
            state.logo = payload
        },
        SET_ENTITYNAME: (state, payload) => {
            state.entityName = payload
        },
        RESET_STATES(state) {
            state.entities = ''
            state.logo = ''
            state.entityName = ''
            state.entityInfo = {}
        },
    },
    actions: {
        setUserEntity: async ({commit, dispatch}, data) => {
            try {
                commit('SET_ENTITY', data)
                commit('SET_LOGO', data.logo)
                commit('SET_ENTITYNAME', data.name)
            } catch (error) {
                console.log(error)
            }
        },
    },
    getters: {
        entity: state => state.entity,
        logo: state => state.logo,
    },
}
