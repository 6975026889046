<template>
    <q-page>
        <div class="row q-pt-xl">
            <q-space />
            <div class="col-lg-7 col-md-9 col-sm-10 col-xs-12">
                <div class="row q-mb-md q-px-md">
                    <div :class="`text-h4 w700 text-${entity.accentColor}`">Cotizaciones</div>
                </div>
                <div class="row q-mb-md q-px-md">
                    <q-btn
                        flat
                        label="Atrás"
                        class="w700"
                        icon="fas fa-long-arrow-alt-left"
                        rounded
                        size="sm"
                        to="/"
                        :color="entity.accentColor"
                    />
                    <q-space />
                    <q-btn
                        flat
                        label="Nuevo"
                        class="w700"
                        icon-right="far fa-file"
                        rounded
                        size="sm"
                        to="/document/create/quote"
                        :color="entity.primaryColor"
                    />
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 q-px-md">
                        <div class="row">
                            <q-table
                                class="full-width"
                                :data="allQuotes"
                                :columns="columns"
                                row-key="name"
                                binary-state-sort
                                :pagination="initialPagination"
                            >
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td key="number" :props="props">{{
                                            props.row.number
                                        }}</q-td>
                                        <q-td key="client" :props="props">{{
                                            props.row.clientData.name
                                        }}</q-td>
                                        <q-td key="monto">
                                            {{ formatCurrency(calculateSubTotal(props.row)) }}</q-td
                                        >
                                        <q-td key="ibms" v-if="entity.collectsTaxes">
                                            {{ formatCurrency(calculateITBMS(props.row)) }}</q-td
                                        >
                                        <q-td key="total" v-if="entity.collectsTaxes">
                                            {{ formatCurrency(calculateTotal(props.row)) }}</q-td
                                        >
                                        <q-td>
                                            <q-btn-group rounded flat>
                                                <q-btn
                                                    icon="delete"
                                                    size="sm"
                                                    flat
                                                    @click="deleteQuote(props.row)"
                                                />
                                                <q-btn
                                                    icon="fas fa-edit"
                                                    size="sm"
                                                    flat
                                                    :to="`/document/edit/quote/${props.row.id}`"
                                                />
                                                <q-btn
                                                    icon="fas fa-arrow-right"
                                                    size="sm"
                                                    flat
                                                    :to="`/document/view/quote/${props.row.id}`"
                                                />
                                            </q-btn-group>
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </div>
            </div>
            <q-space />
        </div>
    </q-page>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
export default {
    data() {
        return {
            initialPagination: {
                sortBy: 'number',
                descending: true,
                rowsPerPage: 15,
            },
            columns: [
                {
                    name: 'number',
                    label: 'No.',
                    align: 'left',
                    field: 'number',
                    sortable: true,
                },
                {
                    name: 'client',
                    label: 'Cliente',
                    align: 'client',
                    field: 'name',
                    sortable: true,
                    align: 'left',
                },
            ],
        }
    },
    computed: {
        allQuotes() {
            return this.$store.getters.allQuotes
        },
        entity() {
            return this.$store.getters.entity
        },
    },
    methods: {
        calculateITBMS(data) {
            let itbms = 0
            if (data.items) {
                if (this.entity.collectsTaxes) {
                    data.items.forEach(item => {
                        item.tax = item.tax
                        itbms += parseFloat(item.price) * (item.tax / 100) * item.amount
                    })
                    return itbms.toFixed(2)
                }
                return 0
            }
        },
        calculateSubTotal(data) {
            let subtotal = 0
            if (data.items) {
                data.items.forEach(item => {
                    subtotal += parseFloat(item.price) * parseFloat(item.amount)
                })
            }
            return subtotal.toFixed(2)
        },
        calculateTotal(data) {
            return this.entity.collectsTaxes
                ? parseFloat(this.calculateSubTotal(data)) + parseFloat(this.calculateITBMS(data))
                : parseFloat(this.calculateSubTotal(data))
        },
        formatCurrency(money, amount = null) {
            if (amount) money = parseFloat(money) * parseFloat(amount)
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(money)
        },
        async deleteQuote(quote) {
            if (confirm('Estas seguro que deseas eliminar esta cotización?')) {
                try {
                    await firebase.firestore().collection('quotes').doc(quote.id).delete()
                    this.$store.dispatch('getAllQuotes', this.entity.id)
                } catch (error) {
                    console.log(error)
                }
            }
        },
    },
    async mounted() {
        this.$store.dispatch('getAllQuotes', this.entity.id)
        if (this.entity.collectsTaxes) {
            this.columns.push(
                {name: 'monto', label: 'Monto', align: 'left'},
                {name: 'itbms', label: 'Itbms', align: 'left'},
                {name: 'total', label: 'Total', align: 'left'},
                {name: 'actions', label: 'Acciones', align: 'left'}
            )
        } else {
            this.columns.push(
                {name: 'monto', label: 'Total', align: 'left'},
                {name: 'actions', label: 'Acciones', align: 'left'}
            )
        }
    },
}
</script>
